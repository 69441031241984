/**
 *  @description
 *  Axios Http Request Interceptors.
 *  @author
 *  Mahesh Hegde<sanjay&#64;myhealthcare.life>
 *  @version
 *  1.0.0 (14-Dec-2022)
 */
import axios from 'axios';
import {
    store
} from '../redux/store/configure-store';

const instance = axios.create();

instance.interceptors.request.use(function (config) {
    const state = store.getState();
    if (state.pair && state.pair.data && state.pair.data.headers) {
        config.headers.Authorization = state.pair.data.headers.authorization;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default instance;