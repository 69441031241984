/**
 *  @description
 *  Index file for the QMS App.
 *  @author
 *  Mahesh Hegde<sanjay&#64;myhealthcare.life>
 *  @version
 *  1.0.0 (18-Dec-2022)
 */

import React from 'react';
import ReactDOM from 'react-dom';
import {
    Provider
} from 'react-redux';
import {
    store,
    persistor,
} from './redux/store/configure-store';
import { PersistGate } from 'redux-persist/integration/react';
import LoadingComponent from './redux/components/loading-component';
import LayoutComponent from './redux/components/layout/layout-component';
import AppRoutes from './routes/app-routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import './stylesheets/index.css';
import * as serviceWorker from './serviceWorker';

const appRoot = document.getElementById('app_root');

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<LoadingComponent />} persistor={persistor}>
            <LayoutComponent>
                <AppRoutes />
            </LayoutComponent>
        </PersistGate>
    </Provider>,
    appRoot
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();